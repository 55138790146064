import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import produce from "immer";
import {
  selectDayOrders,
  getOrderDetails,
  getOrderStats,
  selectNewOrders,
  // selectLocations,
  selectStatistics,
  selectLoaded,
  getMonitorOrders,
  acceptMonitorOrder,
  updateMonitorOrder,
  dispatchMonitorOrder,
  assignMonitorDriver,
  finishMonitorOrder,
  cancelMonitorOrder,
  resendCirebaOrder,
  sendPeyaOrder,
  // deliveryLocation,
  getBranchDrivers,
  transferMonitorOrder,
  // updateNotesClient,
  setLoaded,
} from "../../redux/slices/monitor";
import { selectUser } from "../../redux/slices/user";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
import MonitorUI from "./components/MonitorUI";
import { getPromosListThunk } from "../../redux/slices/promosList";
import NewMonitorUI from "./components/NewMonitorUI";
import { cancellationReasonListThunk, selectCancellationReasons } from "../../redux/slices/cancellationReason";
const Monitor = () => {
  const [modalOrder, handleModalOrder] = useState(false);
  // const [modalCommentaries, handleModalCommentaries] = useState(false);
  const [loadingOrder, handleLoadingOrder] = useState(false);
  const [modalDriverConfirm, handleModalDriverConfirm] = useState(false);
  const [orderDetail, handleOrderDetail] = useState({});
  const lastOrder =  useRef(null)
  const newOrders = useSelector(selectNewOrders);
  const dayOrders = useSelector(selectDayOrders);
  const branches = useSelector(selectBranches);
  const statistics = useSelector(selectStatistics);
  const reasons = useSelector(selectCancellationReasons)
  const loaded = useSelector(selectLoaded);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const branches = await dispatch(getAllBranches(null, true));
    const [response] = await Promise.all([
      dispatch(getMonitorOrders(user.branch, user.admin)),
      dispatch(getOrderStats(user.branch, user.admin)),
      dispatch(cancellationReasonListThunk())
      // dispatch(getPromosListThunk()) // para mostrar las promos dia del nino
    ]);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    }
  }, [dispatch, user]);
  
  useEffect(() => {
    const getEverything = async () => {
      await initialFetch();
      dispatch(setLoaded(true));
    };
    getEverything();
  }, [dispatch, initialFetch]);

  useEffect(() => {
    const countup = setInterval(initialFetch, 30000);

    return () => {
      clearInterval(countup);
    };
    // initialFetch();
  }, [initialFetch]);

  const openDetails = async (order) => {
    handleLoadingOrder(order);
    const response = await dispatch(getOrderDetails(order));
    handleLoadingOrder(false);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      handleModalOrder(true);
    }
  };

  const closeDetails = () => {
    handleModalOrder(false);
    handleOrderDetail({});
  };

  const updateOrderStatus = (status) => {
    const newOrder = produce(orderDetail, (draftState) => {
      draftState.status = status;
    });
    handleOrderDetail(newOrder);
  };

  const openDriverConfirm = (order) => {
    handleModalDriverConfirm(true);
  };

  const closeDriverConfirm = () => {
    handleModalDriverConfirm(false);
    handleModalOrder(true);
  };

  /* const openModalCommetaries = (type) => {
    handleModalCommentaries(type);
  };

  const closeModalCommetaries = () => {
    handleModalCommentaries(false);
    handleModalOrder(true);
  }; */

  const acceptOrder = async () => {
    const response = await dispatch(
      acceptMonitorOrder(orderDetail.id, user.branch)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(7);
      message.success("Orden en preparación");
    }
  };

  /* const updateNotes = async (notes) => {
    const response = await dispatch(
      updateNotesClient(
        orderDetail.Customer.userId,
        orderDetail.Address.id,
        notes,
        orderDetail.id
      )
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Notas editadas");
    }
  }; */

  const updateOrder = async () => {
    if(orderDetail.orderType == 2) {
      const response = await dispatch(dispatchMonitorOrder(orderDetail.id));
      if (response.status !== "success") {
        message.error("Hubo un problema");
      } 
      else {
        updateOrderStatus(10);
        message.success("Orden lista para el driver");
      }
    } 
    else {
      const response = await dispatch(updateMonitorOrder(orderDetail.id));
      if (response.status !== "success") {
        message.error("Hubo un problema");
      } 
      else {
        updateOrderStatus(11);
        message.success("Orden lista para recoger");
      }
    }
  };

  const assignOrder = async (driver) => {
    const response = await dispatch(
      assignMonitorDriver(orderDetail.id, driver)
    );
    console.log('asignado y respuesta');
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      //handleOrderDetail(response.order);
      //await initialFetch();
      updateOrderStatus(10);
      message.success("Orden asignada");
    }
  };
 
  const transferOrder = async (branch, reason) => {
    const response = await dispatch(
      transferMonitorOrder(orderDetail.id, branch, user.id, reason)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } 
    else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(7);
      message.success("Orden transferida");
    }
  };

  const finishOrder = async () => {
    const response = await dispatch(finishMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(12);
      message.success("Orden finalizada");
      // closeModalCommetaries();
    }
  };

  const cancelOrder = async (reason) => {
    const response = await dispatch(cancelMonitorOrder(orderDetail.id,reason));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      // handleOrderDetail(response.order);
      // await initialFetch();
      updateOrderStatus(15);
      message.success("Orden cancelada");
      // closeModalCommetaries();
    }
  };

  const resendOrder = async () => {
    const response = await dispatch(resendCirebaOrder(orderDetail.id, orderDetail.branch.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } 
    else {
      message.success("Orden enviada hacia Punto de Venta");
      // closeModalCommetaries();
    }
  };

  const sendPeya = async () => {
    message.info("Enviando Solicitud a pedidos ya");
    const response = await dispatch(sendPeyaOrder(orderDetail.id, orderDetail.branch.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } 
    else {
      message.success("Orden enviada a Pedidos Ya");
      // closeModalCommetaries();
    }
  }

  const getDriversBranch = async (location) => {
    const response = await dispatch(getBranchDrivers(location));
    if (response.status !== "success") {
      message.error("Hubo un problema");
      return [];
    }
    return response.drivers;
  };

/*
  const handleDelivery = async (idBranch, index, val) => {
    const response = await dispatch(deliveryLocation(idBranch, index, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  }; 
*/

  const isAdmin = user.admin;
  const isDriver = user.driver;
  const playSound = () => {
    const audio = new Audio('/sounds/order-notification.mp3')
    audio.onerror = (e) => {
        console.error('Error al cargar el audio:', e);
    };
    audio.play().catch((error) => {
        console.error('Error al reproducir el audio:', error);
    });
  };
  const alertNewOrder = () => { 
    if(newOrders.length > 0){
      const order = newOrders[0]
      const last = lastOrder.current
      if(order.created_time !== last?.created_time && [1,2].includes(order.orderType)){
        playSound()
        lastOrder.current = newOrders[0]
      }
    }
  }
  
  useEffect(() => {
    alertNewOrder()
  }, [newOrders])
  
  return (
    <>
    {/* <MonitorUI
      modalOrder={modalOrder}
      openDetails={openDetails}
      loadingOrder={loadingOrder}
      newOrders={newOrders}
      dayOrders={dayOrders}
      statistics={statistics}
      // branches={branches}
      loaded={loaded}
      user={user}
      isAdmin={isAdmin}
      isDriver={isDriver}
      orderDetail={orderDetail}
      acceptOrder={acceptOrder}
      updateOrder={updateOrder}
      assignOrder={assignOrder}
      finishOrder={finishOrder}
      cancelOrder={cancelOrder}
      resendOrder={resendOrder}
      sendPeya={sendPeya}
      closeDetails={closeDetails}
      getDriversBranch={getDriversBranch}
      openDriverConfirm={openDriverConfirm}
      // openModalCommetaries={openModalCommetaries}
      // closeModalCommetaries={closeModalCommetaries}
      // modalCommentaries={modalCommentaries}
      // transferOrder={transferOrder}
      // updateNotes={updateNotes}
    /> */}
    <NewMonitorUI
    modalOrder={modalOrder}
    openDetails={openDetails}
    loadingOrder={loadingOrder}
    newOrders={newOrders}
    dayOrders={dayOrders}
    statistics={statistics}
    branches={branches}
    loaded={loaded}
    user={user}
    isAdmin={isAdmin}
    isDriver={isDriver}
    orderDetail={orderDetail}
    acceptOrder={acceptOrder}
    updateOrder={updateOrder}
    assignOrder={assignOrder}
    finishOrder={finishOrder}
    cancelOrder={cancelOrder}
    resendOrder={resendOrder}
    sendPeya={sendPeya}
    closeDetails={closeDetails}
    getDriversBranch={getDriversBranch}
    openDriverConfirm={openDriverConfirm}
    // openModalCommetaries={openModalCommetaries}
    // closeModalCommetaries={closeModalCommetaries}
    // modalCommentaries={modalCommentaries}
    transferOrder={transferOrder}
    // updateNotes={updateNotes}
    reasons={reasons}
    />
    </>
  );
};

export default Monitor;
