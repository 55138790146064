import instance from "./Request";

export const getAllBranchesAPI = async () => {
  const request = await instance();
  let data = await request.get(`/admin/branches`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getBranches = async (userid) => {
  const request = await instance();
  let data = await request.post(`/branches/${userid==undefined?0:userid}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeTakeOut = async (branch, value, usr) => {
  const request = await instance();
  let data = await request
    .post("/branches/take-out", { id: branch, take_out: value, user: usr  })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeDelivery = async (branch, value, usr) => {
  const request = await instance();
  let data = await request
    .post("/branches/delivery", { id: branch, delivery: value, user: usr })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getDepartments = async () => {
  const request = await instance("SV");
  let data = await request.get("/main/departments").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getSchedule = async () => {
  const request = await instance();
  let data = await request.get("/main/info-timedelivery").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateSchedule = async (open, close) => {
  const request = await instance();
  let data = await request
    .post("/main/info-timedelivery", {
      open,
      close,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};



export const newBranch = async (branch) => {
  const request = await instance();
  let data = await request.post("/branches/create", branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editBranch = async (branch) => {
  const request = await instance();
  let data = await request.post("/branches/update", branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteBranch = async (id) => {
  const request = await instance();
  let data = await request.post("/branches/delete", { id }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};



export const getBranch = async (branch) => {
  const request = await instance();
  let data = await request.get(`/branch/${branch}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const uploadImage = async (image) => {
  const request = await instance(true);
  let data = await request.post("/branches/image", image).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const createBranchBackup = async (data) => {
  const request = await instance()
  const response = await request.post('/branch/backup',data).catch((error)=> {
    return {
      error
    }
  })
  return response
}

export const editBranchBackup = async (data) => {
  const request = await instance()
  const response = await request.post('/branch/edit-backup',data).catch((error)=> {
    return {
      error
    }
  })
  return response
}

export const getBranchesBackup = async (userid) => {
  const request = await instance()
  const response = await request.post(`branch/list-backup/${userid==undefined?0:userid}`).catch((error)=>{
    return {
      error
    }
  })
  return response
}

export const deleteBranchBackup = async (userId,branchId) => {
  const request = await instance()
  const response = await request.post('branch/remove-backup',{userId:userId,branchId:branchId}).catch((error)=> {
    return {
      error
    }
  })
  return response
}