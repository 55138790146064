import {
  Table,
  Switch,
  Button,
  Tooltip,
  Space,
  Modal,
  Select,
} from "antd";
import { selectUser } from "../../../redux/slices/user";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import styles from "../styles/ProductsUI.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PriceEditor from "./PriceEditor";
import { useMemo } from "react";
import { getAllAdminProducts, selectCurrentBranch } from "../../../redux/slices/products";
const ProductsUI = (props) => {
  const {
    loading,
    products,
    branches,
    handleProductActive,
    handleBranchChange,
    categories,
    visibleEditor,
    closeEditor,
  } = props;
  const user = useSelector(selectUser);
  const currentBranch = useSelector(selectCurrentBranch);
  const dispatch = useDispatch();

  const getCategoryName = (role) => {
    const index = categories.findIndex((el) => el.value === role);
    if (index > -1) {
      return categories[index].text;
    }
    return "-";
  };

  const irhacia = useNavigate();
  const goToCondiments = () => {
    irhacia(`/productos-por-sucursal/${currentBranch}/salsas`);
  };
  const goToSuggets = () => {
    irhacia(`/productos-por-sucursal/${currentBranch}/recomendados`);
  };

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 200,
      render: (val) => (
        <Tooltip placement="bottom" title={val}>
          <span className={styles.description}>{val}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Disponibilidad",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() =>
            handleProductActive(record.id, val, "take_out", currentBranch || user.branch, user.id) // agregar id de usuario
          }
        />
      ),
    },
  ];

  const branchesSelect = useMemo(() => {
    return branches.map((branch) => ({
      value: branch?.id,
      label: branch?.zone,
    }));
  }, [branches])

  return (
    <div className={styles.view}>
      <Header title="Productos por sucursal" actions={<>
        <Select
          style={{ width: 200 }}
          options={[...branchesSelect]}
          value={currentBranch || user.branch}
          defaultValue={currentBranch || user.branch}
          onChange={async (value) => {
            handleBranchChange(value)
            await dispatch(getAllAdminProducts(value))
          }}
        />
      </>} />
      <div className={styles.buttons}>
        <Space style={{ paddingBottom: "1em" }}>
          <Button
            onClick={goToCondiments}
            type="primary"
            size="large"
            className={styles.mainButton}
          >
            CONDIMENTS
          </Button>
          <Button
            onClick={goToSuggets}
            type="primary"
            size="large"
            className="main-button"
          >
            RECOMENDADOS/SUGERIDOS
          </Button>
        </Space>
      </div>
      <Modal
        destroyOnClose
        wrapClassName={styles.modal}
        footer={false}
        visible={visibleEditor}
        width={300}
        closable={false}
        onCancel={closeEditor}
      >
        <PriceEditor closeEditor={closeEditor} />
      </Modal>
      <Table
        columns={columns}
        dataSource={products}
        rowKey="id"
        loading={loading}
      />
    </div>
  );
};

export default ProductsUI;
