import instance from "./Request";

export const getProducts = async (branch) => {
  const request = await instance();
  let data = await request.post("/partners/menu/any/44", {branchId: branch}).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getAdminProducts = async (branchId) => {
  const request = await instance();
  let data = await request.post("/admin/menu-branch", {
    branchId
  }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeActive = async (
  product,
  active,
  platform,
  branch,
  userId
) => {
  const request = await instance();
  let data = await request
    .post("/products/disable", {
      skus: [{ sku: product, platform, active, branch, userId }],
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getExtras = async (branch) => {
  const request = await instance();
  let data = await request
    .post("/partners/extras/any/44", { branch_id: branch })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeExtraActive = async (
  extra,
  active,
  platform,
  branch,
  cat,
  userId
) => {
  const request = await instance();
  let data = await request
    .post("/extras/disable", {
      skus: [{ sku: extra, platform, active, branch, cat, userId }],
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeSuggested = async (extra, active, platform, branch, cat, userId) => {
  const request = await instance();
  let data = await request
    .post("/suggested/disable", {
      skus: [{ sku: extra, platform, active, branch, cat, userId }],
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const menuCentralAdmin = async (userId) => {
  const request = await instance();
  let data = await request
    .post("/admin/menu-central", { userId })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const menuExtrasAdmin = async (userId) => {
  const request = await instance();
  let data = await request.post("/admin/mc-extras", { userId })
    .catch((error) => {
      return {
        error
      }
    })
  return data
}

export const disableMenuItemAdmin = async (userId, sku, mode) => {
  const request = await instance();
  let data = await request
    .post("/admin/mc-disable-prod", { userId, sku, mode })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const disableMenuItemExtraAdmin = async (userId, sku, mode) => {
  const request = await instance();
  let data = await request.post("/admin/mc-disable-extra", { userId, sku, mode })
    .catch((error) => {
      return {
        error
      }
    })
  return data
}

export const importItems = async () => {
  const request = await instance()
  let data = await request.post("/settings/import-items")
    .catch((error) => {
      return {
        error
      }
    })
  return data
}