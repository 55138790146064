import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  handleActive,
  selectProducts,
  selectCategories,
  disableMenuItemAdminThunk,
  importItemsThunk,
  handleCurrentBranch,
  selectCurrentBranch,
  getAllAdminProducts,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import ProductsUI from "./components/ProductsUI";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";

const ProductsBySucursal = () => {
  const [loading, handleLoading] = useState(true);
  const products = useSelector(selectProducts);
  const branches = useSelector(selectBranches)
  const categories = useSelector(selectCategories);
  const currentBranch = useSelector(selectCurrentBranch);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);  
  const [visibleEditor, handleVisibleEditor] = useState(false)

  const initialFetch = useCallback(async () => {
    handleLoading(true);

    const branches = await dispatch(getAllBranches(null, true));
    const productsBySucursal = await dispatch(getAllAdminProducts( currentBranch || user.branch));

    if (productsBySucursal.status !== "success" || branches.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleProductActive = async (idProduct, val, type, branch, userId) => {
    const response = await dispatch(handleActive(idProduct, val, type, branch, userId));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const confirmVisibility = (sku, mode, active) => {
    Modal.confirm({
      title: `¿${active ? 'Desactivar' : 'Activar'} para todas las sucursales?`,
      okText: "Si",
      cancelText: "No",
      async onOk() {
        const response = await dispatch(disableMenuItemAdminThunk(user.id, sku, mode))
        console.log(response)
        if (response.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        initialFetch();
      },
      onCancel() {

      },
    });
  }
  const closeEditor = () => {
    handleVisibleEditor(false)
  }
  const openEditor = () => {
    handleVisibleEditor(true)
  }

  const importItems = async () => {
    const response = await dispatch(importItemsThunk())
    console.log(response)
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  }

  const handleBranchChange = async (branch) => {
    handleLoading(true);
    await dispatch(handleCurrentBranch(branch))
    handleLoading(false);
  }
  return (
    <ProductsUI
      loading={loading}
      products={products}
      branches={branches}
      initialFetch={initialFetch}
      handleProductActive={handleProductActive}
      handleBranchChange={handleBranchChange}
      categories={categories}
      confirmVisibility={confirmVisibility}
      visibleEditor={visibleEditor}
      closeEditor={closeEditor}
      openEditor={openEditor}
      importItems={importItems}
    />
  );
};

export default ProductsBySucursal;
