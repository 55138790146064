import { Table, Switch, Button, Spin, Tooltip, Dropdown, Menu, Modal } from "antd";
import { selectUser } from "../../../redux/slices/user";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import styles from "../styles/ProductsUI.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as MoreDetail } from "../../../assets/icons/more-detail.svg";
import PriceEditor from "../../products/components/PriceEditor";

const SuggestsUI = (props) => {
  const {
    loading,
    products,
    handleProductSection,
    categories,
    confirmVisibility,
    visibleEditor,
    closeEditor,
    openEditor,
  } = props;

  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const getCategoryName = (role) => {
    const index = categories.findIndex((el) => el.value === role);
    if (index > -1) {
      return categories[index].text;
    }
    return "-";
  };

  const irhacia = useNavigate();

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 200,
      render: (val) => (
        <Tooltip placement="bottom" title={val}>
          <span className={styles.description}>{val}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      filteredValue: [88, 16],
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Disponibilidad",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductSection(record.id, val, "recomendado", user.branch, user.id)}
        />
      ),
    },

    /*  {
       title: "Sugerido",
       align: "center",
       dataIndex: "suggested",
       filters: [
         {
           text: "Activo",
           value: 1,
         },
         {
           text: "Inactivo",
           value: 0,
         },
       ],
       filterMultiple: false,
       onFilter: (value, record) => record.suggested === value,
       render: (val, record) => (
         <Switch
           checked={val}
           onChange={() =>
             handleProductSection(record.id, val, "take_out", user.branch)
           }
         />
       ),
     }, */
  ];
  const options = (
    <Menu>
      <Menu.Item>Actualizar Imagen</Menu.Item>
      <Menu.Item onClick={openEditor}>Actualizar Precio</Menu.Item>
    </Menu>
  );
  const columnsAdmin = [
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "WEB",
      align: "center",
      dataIndex: "active_web",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "web", active)} />
      ),
    },
    {
      title: "APP",
      align: "center",
      dataIndex: "active_app",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "app", active)} />
      ),
    },
    {
      title: "PEYA",
      align: "center",
      dataIndex: "active_peya",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "peya", active)} />
      ),
    },
    {
      title: "UBER",
      align: "center",
      dataIndex: "active_uber",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "uber", active)} />
      ),
    },
    /*  {
       title: "Acciones",
       align: "center",
       dataIndex: "id",
       render: () => (
         <Dropdown overlay={options} className={styles.menu}>
           <MoreDetail />
         </Dropdown>
       ),
     }, */
  ];

  return (
    <div className={styles.view}>
      <Header back={pathname.includes("productos-por-sucursal") ? "/productos-por-sucursal" : "/productos"} title="Productos Sugeridos" />
      <Modal
        destroyOnClose
        wrapClassName={styles.modal}
        footer={false}
        visible={visibleEditor}
        width={300}
        closable={false}
        onCancel={closeEditor}
      >
        <PriceEditor closeEditor={closeEditor} />
      </Modal>

      <Table columns={user.admin === 1 && !pathname.includes("productos-por-sucursal") ? columnsAdmin : columns} dataSource={products} rowKey="id" loading={loading} />
    </div>
  );
};

export default SuggestsUI;
