import {
  Tabs,
  Table,
  Switch,
  Spin,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { getColumnSearchProps } from "../../../utils/tables";
import { selectUser } from "../../../redux/slices/user";
import Header from "../../../components/HeaderList";
import styles from "../styles/CondimentUI.module.css";
import { useSelector } from "react-redux";
import { ReactComponent as MoreDetail } from "../../../assets/icons/more-detail.svg";
import PriceEditor from "../../products/components/PriceEditor";
import { useLocation } from "react-router-dom";
import { selectCurrentBranch } from "../../../redux/slices/products";

const { TabPane } = Tabs;

const CondimentUI = (props) => {
  const {
    loading,
    extras,
    handleExtrasActive,
    confirmVisibility,
    visibleEditor,
    closeEditor,
    openEditor,
  } = props;

  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const currentBranch = useSelector(selectCurrentBranch);

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${Number(val).toFixed(2)}`,
    },
    {
      title: "Disponibilidad",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() =>
            handleExtrasActive(record.id, val, "take_out", currentBranch || user.branch, "157", user.id)
          }
        />
      ),
    },
    /*   {
        title: "Eat In",
        align: "center",
        dataIndex: "eat_in",
        filters: [
          {
            text: "Activo",
            value: 1,
          },
          {
            text: "Inactivo",
            value: 0,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.eat_in === value,
        render: (val, record) => (
          <Switch
            checked={val}
            onChange={() =>
              handleExtrasActive(record.id, val, "eat_in", user.branch, "157")
            }
          />
        ),
      }, */
  ];

  const columns2 = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${Number(val).toFixed(2)}`,
    },

    /*  {
      title: "Take Out",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "take_out", user.branch, "239")}
        />
      ),
    },
    {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleExtrasActive(record.id, val, "eat_in", user.branch, "239")}
        />
      ),
    }, */
  ];
  const options = (
    <Menu>
      <Menu.Item>Actualizar Imagen</Menu.Item>
      <Menu.Item onClick={openEditor}>Actualizar Precio</Menu.Item>
    </Menu>
  );
  const columnsAdmin = [
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "WEB",
      align: "center",
      dataIndex: "active_web",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "web", active)} />
      ),
    },
    {
      title: "APP",
      align: "center",
      dataIndex: "active_app",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "app", active)} />
      ),
    },
    {
      title: "PEYA",
      align: "center",
      dataIndex: "active_peya",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "peya", active)} />
      ),
    },
    {
      title: "UBER",
      align: "center",
      dataIndex: "active_uber",
      render: (active, record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "uber", active)} />
      ),
    },
    /*   {
        title: "Acciones",
        align: "center",
        dataIndex: "id",
        render: () => (
          <Dropdown overlay={options} className={styles.menu}>
            <MoreDetail />
          </Dropdown>
        ),
      }, */
  ];
  return (
    <div className={styles.view}>
      <Header title="Salsas" back={pathname.includes("productos-por-sucursal") ? "/productos-por-sucursal" : "/productos"} />
      <Modal
        destroyOnClose
        wrapClassName={styles.modal}
        footer={false}
        visible={visibleEditor}
        width={300}
        closable={false}
        onCancel={closeEditor}
      >
        <PriceEditor closeEditor={closeEditor} />
      </Modal>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Salsas" key="1">
          <Table
            columns={user.admin === 1 && !pathname.includes("productos-por-sucursal") ? columnsAdmin : columns}
            dataSource={extras.extras}
            rowKey="id"
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Picante" key="2">
          <Table
            columns={user.admin === 1 && !pathname.includes("productos-por-sucursal") ? columnsAdmin : columns}
            dataSource={extras.extras2}
            rowKey="id"
            loading={loading}
          />
        </TabPane>
      </Tabs>

    </div>
  );
};

export default CondimentUI;
